<template>
  <div class="head_top">
    <img src="../../../../public/images/二级海报/加盟-授权-二次.jpg" width="100%" height="100%" lazy>

    <div class="size_box">
      <div class="title">加入我们</div>
      <h3>加盟条件:</h3>
      <p>拥有良好的财务状况和资金信誉；具有一定的安防市场背景与渠道销售网络，能按时完成代理期限内承诺的任务量；具备一定的售后能力。</p>


      <h3>加盟政策:</h3>
      <h4>对代理商的支持</h4>
      <p>(1) 区域保护和项目支持政策</p>
      <p>根据区域竞争及客户需要，智驹将提供多项特别支持帮助合作伙伴顺利开展项目：</p>
      <p>1）特价支持；</p>
      <p>2）售前技术支持；</p>
      <p>2）定制产品支持；</p>
      <p>4）人员及前期市场投入支持等。</p>
      <p>(2) 品牌推广活动支持</p>
      <p>
        我公司会长期在有影响力的媒体上投放广告，提高智驹品牌的知名度，以支持代理商在当地的销售工作；不定期参加全国性展会，提前将参展信息通知合作伙伴，邀请合作伙伴及用户前来参观；配合合作伙伴在当地举办市场活动，营造良好的市场环境，创造销售机会；并推出达量返利政策与市场推广广告基金政策，对合作伙伴的努力与支持进行奖励。</p>

      <h3>加盟流程:</h3>
      <p>智驹核心团队从事视频软件开发十余年，积累了丰富的经验和行业人脉关系，同时依托工厂优势，直接面对国内外市场，积极调整产品结构，建立了稳定的客户群体。面对数字监控越来越广泛的市场需求，现提供以下服务模式：</p>

      <h3>代理商合作:</h3>
      <p>
        主流的合作模式，智驹长远目标就是设立成熟的代理商机制，组建完善的营销渠道。省级代理级别最高，下设市级代理和分销商。明确各代理商区域，实行区域保护。同级别代理商全国执行统一价格，按实际销售额结算返点。代理商可享受智驹各类资源的共享：客户资源、网络推广、技术文档、参加展会等。</p>
      <p>代理商流程:</p>
      <p> 详情可咨询公司业务员.</p>
      <p> 想了更多详情，请 联系我们!</p>

    </div>
  </div>
</template>

<script>
export default {
  name: "加盟代理"
}
</script>
<style scoped>
.size_box {
  width: 80%;
  margin: auto;
}

.title {
  text-align: center;
  font-size: 24px;
  margin-top: 50px;
}

p {
  margin-bottom: 20px;
  text-indent: 2em;
}
</style>